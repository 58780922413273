import React from 'react'
import CustomContainer from '../Components/Container'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import LocationBox from '../Components/LocationBox'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../Style/style.css';
import { Pagination } from 'swiper/modules';
import useWindowDimensions from '../hooks/getFunctionDimensions';

const Title = styled.span`
    font-size: 35px;
    font-weight:700;
    line-height:40px;
`

const BlueText = styled.span`
    color:#0073C9;
`

const Desc = styled.span`
display:block;
    width: 100%;
    max-width:661px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
`

const Section3 = () => {
    const { width } = useWindowDimensions();
    return (
        <CustomContainer>
            <SectionBreaker>
                <Grid container rowSpacing={5} justifyContent={'center'} textAlign={'center'} >
                    <Grid item xs={12}>
                        <Title>Wo <BlueText>finden</BlueText> Sie uns?</Title>
                    </Grid>
                    <Grid item>
                        <Desc>Lorem ipsum dolor sit amet consectetur.
                            Non enim molestie augue urna consectetur lacus nulla pulvinar. Morbi ut dolor lacus placerat pharetra.
                        </Desc>
                    </Grid>
                    <Grid item>
                        {
                            width > 700.98
                                ? (<Grid container rowSpacing={6} columnSpacing={5} justifyContent={'center'}>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Bern"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Lausanne"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Geneva"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Basel"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Zurich"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <LocationBox
                                            place={"Lucerne"}
                                            desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                        />
                                    </Grid>
                                </Grid>)
                                : (<Swiper
                                    spaceBetween={300}
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 'auto',
                                            spaceBetween: 10,
                                            centeredSlides: true
                                        },
                                        600: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        800: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        1200: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                    }} pagination={true} modules={[Pagination]} className="locationSwiper">
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Bern"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Lausanne"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Geneva"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Basel"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Zurich"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={'auto'}>
                                            <LocationBox
                                                place={"Lucerne"}
                                                desc={"Lorem ipsum dolor sit amet consectetur. Enim amet varius libero."}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                </Swiper>)
                        }
                    </Grid>
                </Grid>
            </SectionBreaker>
        </CustomContainer >

    )
}

export default Section3