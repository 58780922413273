import React from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Input from 'react-phone-number-input/input'

const InputWrapper = styled.div`
    width:100%;
    height:55px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    display: flex;
    align-items: center;
`

const PhoneItem = styled.span`
    padding-left: 15px;
`

const NumberItem = styled.span`
    color:#7D8592;
    padding:0px 10px;
`

const PhoneItems = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    border-right:1px solid #EFF0F6;
 
`

const PhoneInput = ({ svg, input, style, name, value, pattern, setFormData, formData }) => {

    return (
        <Grid container>
            <InputWrapper style={{ ...style }}>
                <Grid container textAlign={'left'} alignItems={'center'}>

                    {
                        svg && (
                            <Grid item xs={'auto'}>
                                <PhoneItems>
                                    <Grid item>
                                        <PhoneItem>
                                            {svg}
                                        </PhoneItem>
                                    </Grid>
                                    <Grid item>
                                        <NumberItem>
                                            +41
                                        </NumberItem>
                                    </Grid>
                                </PhoneItems>
                            </Grid>
                        )
                    }

                    <Grid item xs>
                        <Input
                            country="CH"
                            international
                            pattern="^(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91)(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                            minLength={12}
                            maxLength={12}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    [name]: e,
                                });
                            }}
                            autoComplete='new-password'
                            name={name}
                            value={value}
                            placeholder={input}
                            style={{ border: "none", paddingLeft: "10px", outline: "none" }}
                        />
                    </Grid>
                </Grid>
            </InputWrapper>
        </Grid>
    )
}

export default PhoneInput