import React from 'react'
import CustomContainer from '../Components/Container'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
import threeimgs from '../assets/images/threeimgs.png';
import styled from 'styled-components';

const Title = styled.span`
display:block;
    font-size: 35px;
    font-weight:700;
    line-height:40px;
`

const BlueText = styled.span`
    color:#0073C9;
`

const Desc = styled.span`
    display:block;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
`

const Section2 = () => {
    return (
        <CustomContainer>
            <SectionBreaker>
                <Grid container spacing={5} justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                    <Grid item xs={12} md={6} lg={4} order={2}>
                        <Grid container rowSpacing={5} justifyContent={'center'} textAlign={'center'}>
                            <Grid item xs={12}>
                                <Title>
                                    Über <BlueText>uns</BlueText>
                                </Title>
                            </Grid>
                            <Grid item xs>
                                <Desc>
                                    Lorem ipsum dolor sit amet consectetur. Fermentum velit urna leo accumsan.
                                    Volutpat platea nibh amet pellentesque id eu vestibulum. Enim gravida laoreet vestibulum lorem nisl enim.
                                    Laoreet id fermentum.
                                    Nibh sed adipiscing nibh facilisis volutpat gravida nec. Interdum donec diam semper amet porta.
                                </Desc>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} order={1}>
                        <Grid item >
                            <img style={{ width: "100%" }} src={threeimgs} alt="three images" />
                        </Grid>
                    </Grid>
                </Grid>
            </SectionBreaker>
        </CustomContainer>

    )
}

export default Section2