import React, {useState, useRef} from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const InputWrapper = styled.div`
    width:100%;
    min-height: 100px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    display: flex; 
    align-items: center;
`;

const InputItem = styled.span`
    font-size: 17px;
    font-weight: 400;
    line-height:20px;
    padding:20px 0px;
    padding-left: 15px;
    color:#171717;

    input {
        width: 100%;
        max-width:350px;
        border: none; 
        outline: none;
        resize: vertical; 
    }

    @media (max-width: 899.98px) {
        input {
            width: 100%;
            max-width:300px;
        }
    }

    @media (max-width: 599.98px) { 
        input {
            width: 100%;
            max-width:300px;
        }
    }
`;

const Area = styled.textarea`
    width:100%;
    max-width:510px;
    min-height: 100px;
    border:none;
    outline:none;
    font-size:18px;
    font-weight:400;
    line-height:20px;
`;

const TextAreaInput = ({ svg, style}) => {
    const textareaRef = useRef(null);
  const [height, setHeight] = useState('auto');

  const handleChange = () => {
    const { scrollHeight } = textareaRef.current;
    setHeight(`${scrollHeight}px`);
  };

    return (
        <Grid container alignItems={'center'}>
            <InputWrapper style={{ ...style }}>
                <Grid container textAlign={'left'} columnSpacing={1.5} pt={2} pb={2}>
                    {
                        svg && (
                            <Grid item xs={'auto'}>
                                <InputItem>
                                    {svg}
                                </InputItem>
                            </Grid>
                        )
                    }
                    <Grid item xs>
                        <Area
                        ref={textareaRef}
                        style={{ height }}
                        onChange={handleChange}
                        placeholder="Beschreibung"
                        />
                    </Grid>
                </Grid>
            </InputWrapper>
        </Grid>
    )
}

export default TextAreaInput