import React from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components';
import 'react-phone-number-input/style.css'

const Title = styled.span`
    display: block;
    width:100%;
    max-width:433px;
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    text-align:left;
    color: #0073C9;


    @media (max-width: 899.98px) {
        margin:auto; 
        text-align:center;
    }

    @media (max-width: 599.98px) { 
        margin:auto; 
        text-align:center;
    }
`;

const Desc = styled.span`
    display: block;
    width:100%;
    max-width: 404.92px;
    font-size: 16px;
    color: #171717;
    font-weight: 400;

    @media (max-width: 899.98px) {
        margin:auto;
        text-align:center;
    }

    @media (max-width: 599.98px) {
        margin:auto; 
        text-align:center;
    }
`;

const Step3 = () => {

    return (

        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Title>
                    Successfully sent
                </Title>
            </Grid>
            <Grid item xs={12}>
                <Desc>
                    Lorem ipsum dolor sit amet consectetur.
                    Non enim molestie augue urna consectetur lacus nulla pulvinar.
                    Morbi ut dolor lacus placerat pharetra.
                </Desc>
            </Grid>
        </Grid>

    )
}

export default Step3