import React, { useRef, useState, useEffect } from 'react';
import SectionBreaker from '../Components/SectionBreaker';
import CustomContainer from '../Components/Container';
import Step1 from '../Components/Step1';
import Step2 from '../Components/Step2';
import Step3 from '../Components/Step3';
import { Grid } from '@mui/material';
import useWindowDimensions from '../hooks/getFunctionDimensions';
import firstRoomImage from '../assets/images/firstroom.png';
import responsiveImg from '../assets/images/responsiveimg.svg'
import styled from 'styled-components';
import Wheel from '../Components/Wheel';


const GridWrapper = styled.div`

    .MuiGrid-root {
       
        @media (max-width: 650.98px) { 
            padding-inline:0rem;
        }
    
        @media (max-width: 599.98px) { 
            padding-inline:0rem;
        }
    }
   
`
const Section1 = () => {

    const [Step, setStep] = useState(1)

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        krankenkasse: '',
        plz: '',
        ort: '',
        strasse: '',
        mobileverification: '',
        emailverification: '',
        item: ''
    });
    

    const stepsRef = useRef(null);

    useEffect(() => {
        var scrollDiv = stepsRef.current.offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth' });

    }, [Step])
    
    const { width } = useWindowDimensions();

    return (
        <CustomContainer>
            <SectionBreaker>
                <GridWrapper>
                    <Grid container paddingInline={"5rem"} rowSpacing={5} columnSpacing={4} justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={12} sm={12} md={6} pt={0}>
                            {
                                width > 700.98 ?
                                    (<img style={{ width: "90%" }} src={firstRoomImage} alt="First Room" />) :
                                    (<img style={{ width: "100%" }} src={responsiveImg} alt="First Room" />)
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div ref={stepsRef}></div>
                            {
                                {
                                    1: <Step1 setStep={setStep} formData={formData} setFormData={setFormData} />,
                                    // 2: <Step2 setStep={setStep} formData={formData} setFormData={setFormData} />,
                                    3: <Wheel setStep={setStep} formData={formData} setFormData={setFormData}/>,
                                    4: <Step3 />
                                }[Step]
                            }
                          
                            
                        </Grid>
                    </Grid>
                </GridWrapper>
            </SectionBreaker>
        </CustomContainer>
    )
}

export default Section1