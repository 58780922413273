import React from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const InputWrapper = styled.div`
    width:100%;
    height:55px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    display: flex; 
    align-items: center;
`;

const InputItem = styled.span`
    font-size: 17px;
    font-weight: 400;
    line-height:20px;
    padding:20px 0px;
    padding-left: 15px;
    color:#171717;
    input {
        width: 100%;
        max-width:180px;
        border: none;
        outline: none;
        resize: vertical; 
        height:40px;
    }

    @media (max-width: 1535.98px) { 
        input {
            width: 100%;
            max-width:140px;
        }
    }


    @media (max-width: 1199.98px) { 
        input {
            width: 100%;
            max-width:80px;
        }
    }

    @media (max-width: 899.98px) {
        input {
            width: 100%;
            max-width:170px;
        }
    }

    @media (max-width: 599.98px) { 
        input {
            width: 100%;
            max-width:300px;
        }
    }
`;

const Inputtext = styled.input`
    ::placeholder{
        color:#7D8592;
    }
`;

const OrtInput = ({ svg, input, style , handleChange, name, required,value}) => {
  
    return (
        <Grid container alignItems={'center'}>
            <InputWrapper style={{ ...style }}>
                <Grid container textAlign={'left'} rowSpacing={0} alignItems={'center'}>
                    {
                        svg && (
                            <Grid item xs={'auto'}>

                                <InputItem>
                                    {svg}
                                </InputItem>
                                
                            </Grid>
                        )
                    }
                    <Grid item xs>
                        <InputItem>
                             <Inputtext value={value} required={required} style={{ border: "none", outline: "none", resize: "vertical" }} name={`${name}`}  onChange={handleChange} type="text" placeholder={input} />
                        </InputItem>
                    </Grid>
                </Grid>
            </InputWrapper>
        </Grid>
    )
}

export default OrtInput