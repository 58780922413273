import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Location } from '../assets/images/boxlocation.svg'

const BoxWrapper = styled(Grid)`
    width:100%;
    max-width:300px;
    height:250px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    padding:10px 25px;
`

const Place = styled.span`
    color:#171717;
    font-size:24px;
    font-weight:700;
    line-height:25px;
`

const Desc = styled.span`
    display:block;
    font-size:12px;
    font-weight:400;
    line-height:17px;
`


const LocationBox = ({ place, desc }) => {
    return (
        <Grid container>

            <BoxWrapper container rowSpacing={2} textAlign={'center'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid item >
                    <Location />
                </Grid>
                <Grid item>
                    <Place>{place}</Place>
                </Grid>
                <Grid item>
                    <Desc>{desc}</Desc>
                </Grid>
            </BoxWrapper>

        </Grid>
    )
}

export default LocationBox