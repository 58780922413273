import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { ReactComponent as Person } from '../assets/images/person.svg'
import { ReactComponent as Email } from '../assets/images/email.svg'
import { ReactComponent as Krankenkesse } from '../assets/images/krankenkesse.svg'
import { ReactComponent as Location } from '../assets/images/location.svg'
import { ReactComponent as Swiss } from '../assets/images/swiss.svg'
import Input from './Input'
import styled from 'styled-components';
import axios from 'axios'
import PLZInput from './PLZInput';
import PhoneInput from './PhoneInput';
import OrtInput from './OrtInput'

const Title = styled.span`
    display: block;
    width:100%;
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    text-align:left;
    color: #171717;


    @media (max-width: 899.98px) {
        margin:auto; 
        text-align:center;
    }

    @media (max-width: 599.98px) { 
        margin:auto; 
        text-align:center;
    }
`

const BlueText = styled.span`
    font-size: 35px;
    color: #0073C9;
    font-weight:700;
`

const Desc = styled.span`
    display: block;
    width:100%;
    font-size: 16px;
    color: #171717;
    font-weight: 400;

    @media (max-width: 899.98px) {
        margin:auto;
        text-align:center;
    }

    @media (max-width: 599.98px) {
        margin:auto; 
        text-align:center;
    }
`

const Button = styled.button`
    padding: 20px 90px;
    background-color: #0073C9;
    color: #FFFFFF;
    border: none;
    border-radius: 15px;
    &:disabled {
        opacity:0.5;
    }
`

const Wrapper = styled.div`
    .PhoneInput{
        width:100%;
        height:65px;
        border-radius: 15px;
        border: 1px solid #EFF0F6;
        text-align:left;
        box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    }

    .PhoneInputCountry{
        padding:20px 15px;
        padding-right: 2px;
    }

    .PhoneInputInput{
        border:none;
        outline:none;
    }

    .PhoneInputInput::placeholder{
        color:#7D8592;
    }

    .PhoneInputCountryIcon {
        width: 20px;
    }
`

const SelectWrapper = styled.div`
    width:100%;
    height:55px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    padding:10px 15px;
    display:flex;
    align-items:center;
    
    select:invalid {
       color:#7D8592 !important;
    }

    @media (max-width: 899.98px) {
        select:invalid {
            color:#7D8592 !important;
            opacity: 0.7;
        }
    }
`

const SelectInput = styled.select`
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
  
   color:#171717;

   @media (max-width: 899.98px) {
        background-color: transparent;
       
    }

   @media (max-width: 599.98px) { 
        background-color: transparent;
   }
   
`

const IconWrapper = styled.div`
    padding-right: 15px;
`

const Step1 = ({ setStep, formData, setFormData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const data = {
            email: formData.email,
            phoneNumber: formData.phoneNumber
        }

        setIsLoading(true);

        // try {
        //     const response = await axios.post("https://dlfinance.insulana.ch/send-email", data);
        //     console.log('Response:', response.data);
            
        //     setIsLoading(false);
        // } catch (error) {
        //     if (error.response) {
        //         console.error('Server Error:', error.response.data);
        //         console.error('Status Code:', error.response.status);
        //         console.error('Headers:', error.response.headers);
        //     } else if (error.request) {
        //         console.error('No response received:', error.request);
        //     } else {
        //         console.error('Error:', error.message);
        //     }
        //     console.error('Error config:', error.config);
        // }
        setStep(3);
    };
  
    const [IsLoading, setIsLoading] = useState(false)

    const [regions, setRegions] = useState([]);

    useEffect(() => {
        //Get all the regions
        const getRegions = async () => {
            await axios.get(
                'https://node.kutiza.com/krankenkasse/regions'
            )
                .then(function (result) {
                    setRegions(result.data);

                })
        }
        getRegions();
    }, [])

    const [Ort, setOrt] = useState('')

    return (
        <form onSubmit={handleSubmit}  autoComplete='new-password'>
            <Grid container rowSpacing={5} justifyContent={'center'} flexDirection={'column'} >
                <Grid item>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Title>
                                Lorem <BlueText>ipsum</BlueText> dolor sit amet
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Desc>
                                Lorem ipsum dolor sit amet consectetur. Non enim molestie augue urna consectetur lacus nulla pulvinar.
                            </Desc>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                        <Grid item xs={12}>
                            <Input
                                required
                                name="name"
                                svg={<Person />}
                                input={'Vorname und Nachname'}
                                handleChange={handleChange}
                                type={'text'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                required
                                name="email"
                                svg={<Email />}
                                input={'Email'}
                                handleChange={handleChange}
                                type={'email'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Wrapper>
                                <PhoneInput
                                    required
                                    svg={<Swiss />}
                                    name="phoneNumber"
                                    setFormData={setFormData}
                                    formData={formData}
                                    input={'Telefonnummer'}
                                    handleChange={handleChange}
                                />
                            </Wrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectWrapper>
                                <Grid container columnSpacing={0} alignItems={'center'} >
                                    <Grid item>
                                        <IconWrapper>
                                            <Krankenkesse />
                                        </IconWrapper>
                                    </Grid>
                                    <Grid item xs>
                                        <SelectInput required name="krankenkasse" onChange={handleChange}>
                                            <option value="" >Krankenkesse</option>
                                            <option value="32">Aquilana </option>
                                            <option value="312">Atupri</option>
                                            <option value="343">Avenir</option>
                                            <option value="1542">Assura-Basis</option>
                                            <option value="1560">Agrisano</option>
                                            <option value="8">CSS</option>
                                            <option value="290">CONCORDIA</option>
                                            <option value="774">EasySana</option>
                                            <option value="881">EGK</option>
                                            <option value="1386">GALENOS</option>
                                            <option value="1562">Helsana</option>
                                            <option value="376">KPT</option>
                                            <option value="829">KLuG</option>
                                            <option value="1479">Mutuel Assurance (Groupe Mutuel)</option>
                                            <option value="455">ÖKK</option>
                                            <option value="1535">Philos</option>
                                            <option value="1401">Rhenusana</option>
                                            <option value="62">SUPRA</option>
                                            <option value="194">Sumiswalder Krankenkasse</option>
                                            <option value="923">SLKK</option>
                                            <option value="1384">SWICA</option>
                                            <option value="1509">Sanitas</option>
                                            <option value="1568">Sana24</option>
                                            <option value="509">Vivao Sympany</option>
                                            <option value="966">vita surselva</option>
                                            <option value="1555">Visana AG</option>
                                            <option value="1570">Vivacare</option>
                                        </SelectInput>
                                    </Grid>
                                </Grid>
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={16} sm={6}>
                            <PLZInput
                                regions={regions}
                                required
                                name="plz"
                                svg={<Location />}
                                input={'PLZ'}
                                handleChange={handleChange}
                                setOrt={setOrt}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OrtInput
                                required
                                name="ort"
                                svg={<Location />}
                                input={'Ort'}
                                value={Ort}
                                formData={formData.ort}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Input
                                required
                                name="strasse"
                                svg={<Location />}
                                input={'Strasse'}
                                handleChange={handleChange}
                                type={'text'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button type="submit" disabled={IsLoading}>Weiter</Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default Step1