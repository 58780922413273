import React from 'react'
import { Grid } from '@mui/material'
import { ReactComponent as DLFinance } from '../assets/images/dlfinance.svg'
import styled from 'styled-components'


const GridWrapper = styled(Grid)`
    padding: 0px 100px;
`

const Navbar = () => {
    return (
        <>

            <GridWrapper container>
                <Grid item>
                    <DLFinance style={{width:"100%",maxWidth:"500px", height:"100px"}}/>
                </Grid>
            </GridWrapper>

        </>
    )
}

export default Navbar