import React from 'react'
import Section1 from '../Sections/Section1'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Menu from '../Components/Menu'

const LandingPage = () => {
    return (
        <>
            <Menu />
            <div id='home'>
                <Section1 />
            </div>
            <div id='aboutus'>
                <Section2 />
            </div>
            {/* <div id='findus'>
                <Section3 />
            </div> */}
            <div id='getintouch'>
                <Section4 />
            </div>
        </>
    )
}

export default LandingPage