import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ReactComponent as Phone } from '../assets/images/phone.svg'
import { ReactComponent as Email } from '../assets/images/email.svg'
import Input2 from './Input2'
import styled from 'styled-components';
import 'react-phone-number-input/style.css'
import axios from 'axios'

const Title = styled.span`
    display: block;
    width:100%;
    max-width:433px;
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    text-align:left;
    color: #171717;


    @media (max-width: 899.98px) {
        margin:auto; 
        text-align:center;
    }

    @media (max-width: 599.98px) { 
        margin:auto; 
        text-align:center;
    }
`;

const BlueText = styled.span`
    font-size: 35px;
    color: #0073C9;
    font-weight:700;
`;

const Desc = styled.span`
    display: block;
    width:100%;
    max-width: 404.92px;
    font-size: 16px;
    color: #171717;
    font-weight: 400;

    @media (max-width: 899.98px) {
        margin:auto;
        text-align:center;
    }

    @media (max-width: 599.98px) {
        margin:auto; 
        text-align:center;
    }
`;

const Button = styled.button`
    padding: 20px 90px;
    background-color: #0073C9;
    color: #FFFFFF;
    border: none;
    border-radius: 15px;
`

const Blue = styled.span`
    color:#0073C9;
`

const Step2 = ({ setStep, formData, setFormData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const [Error, setError] = useState(false)

    const [IsLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            krank: formData.krankenkasse,
            name: formData.name,
            plz: formData.plz,
            ort: formData.ort,
            code: formData.emailverification,
            phoneCode: formData.mobileverification,
            strasse: formData.strasse
        }

        setIsLoading(true);

        try {
            const response = await axios.post("https://dlfinance.insulana.ch/code-check", data);
            console.log('Response:', response.data);
            setStep(3);
            setIsLoading(false);
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    useEffect(() => {
        localStorage.setItem("email", formData.email)
    }, [formData.email])


    return (

        <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={5} justifyContent={'center'} flexDirection={'column'}>
                <Grid item>
                    <Grid container pb={2} rowSpacing={3}>
                        <Grid item xs={12}>
                            <Title>
                                Lorem <BlueText>ipsum</BlueText> dolor sit amet
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Desc>
                                Lorem ipsum dolor sit amet consectetur. Non enim molestie augue urna consectetur lacus nulla pulvinar.
                            </Desc>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12}>
                        Code wird gesendet an <Blue>{formData.email}</Blue>
                        </Grid>
                        <Grid item xs={12}>
                            <Input2
                                name="emailverification"
                                svg={<Email />}
                                input={'E-Mail-Verifizierungscode'}
                                handleChange={handleChange}
                                error={Error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            Code wird gesendet an <Blue>{formData.phoneNumber}</Blue>
                        </Grid>
                        <Grid item xs={12}>
                            <Input2
                                name="mobileverification"
                                svg={<Phone />}
                                input={'Telefonverifizierungscode'}
                                handleChange={handleChange}
                                error={Error}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ paddingTop: "20px", color: "red" }}>
                        {Error &&
                            (
                                <p>{Error}</p>
                            )
                        }
                    </div>
                </Grid>
                <Grid item>
                    <Button type="submit"  disabled={IsLoading} >Senden</Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default Step2