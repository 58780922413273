import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const InputWrapper = styled.div`
    width:100%;
    height:55px;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    display: flex;
    align-items: center;
`
const InputItem = styled.span`
    font-size: 17px;
    font-weight: 400;
    line-height:20px;
    padding:20px 0px;
    padding-left: 15px;
    color:#171717;
    input {
        width: 100%;
        max-width:190px;
        border: none;
        outline: none;
        resize: vertical; 
       
    }

    @media (max-width: 1535.98px) { 
        input {
            width: 100%;
            max-width:140px;
        }
    }


    @media (max-width: 1199.98px) { 
        input {
            width: 100%;
            max-width:80px;
        }
    }

    @media (max-width: 899.98px) {
        input {
            width: 100%;
            max-width:170px;
        }
    }

    @media (max-width: 599.98px) { 
        input {
            width: 100%;
            max-width:300px;
        }
    }
`;

const Inputtext = styled.span`
    color:#7D8592;
`;
const PLZInput = ({ svg, input, style, name, required, regions, setOrt, formData,setFormData }) => {

    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [filter, setFilter] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false)
    const inputChange = useRef(null)

    const showSelectDropdown = () => {
        setIsFocused(true)
    }

    const hideSelectDropdown = () => {
        setTimeout(() => {
            setIsFocused(false)
        }, 150);
    }

    const handleInput = (e) => {
        inputChange.current.value = e.target.dataset.plz;
        setOrt(e.target.dataset.ort)
        setInputValue(e.target.getAttribute('value'))
        setIsFocused(false)

        setFormData({
            ...formData,
            plz: e.target.dataset.plz,
            ort: e.target.dataset.ort,
        });
    }

    useEffect(() => {
        const searchRegion = (e) => {
            let region;

            if (inputValue.length < 4) {
                if (!isNaN(inputValue)) {
                    region = regions.filter((element) => (element.plz.substr(0, inputValue.length)) === (inputValue))
                }
                else {
                    region = regions.filter((element) => (element.plz + " " + element.ort).includes(inputValue))
                }
            }
            else {
                region = regions.filter((element) => (element.plz + " " + element.ort).includes(inputValue))
            }

            if (inputValue === '' || inputValue === null) {
                setFilter([]);
                setIsEmpty(true)
            }
            else {
                setFilter(region);
                setIsEmpty(false)
            }
        }
        searchRegion()

    }, [inputValue, regions])
    return (
        <Grid container position={'relative'} spacing={0} gap={0}>
            <InputWrapper style={{ ...style }}>
                <Grid container textAlign={'left'} rowSpacing={0} alignItems={'center'}>

                    {
                        svg && (
                            <Grid itemxs={'auto'}>

                                <InputItem>
                                    {svg}
                                </InputItem>

                            </Grid>
                        )
                    }

                    <Grid item xs>
                        <InputItem>
                            <Inputtext>
                                <input
                                    ref={inputChange}
                                    onBlur={hideSelectDropdown}
                                    onFocus={showSelectDropdown} 
                                    required={required} 
                                    style={{ border: "none", outline: "none" }} 
                                    name={name} 
                                    onChange={(e) => { setInputValue(e.target.value) }}
                                    type="text" 
                                    placeholder={input}
                                    autoComplete='off'
                                />
                            </Inputtext>
                        </InputItem>
                    </Grid>
                </Grid>
               
            </InputWrapper>
            {(isFocused && !isEmpty) && (
                        <div className='plzDropdown'>
                            {filter.map(element => {
                                return (
                                    <div key={element.plz + element.ort + element.plz + element.region + element.kanton + element.commune} className='selectOptionStyle' onClick={handleInput} value={element.plz + " " + element.ort} data-region={element.region} data-kanton={element.kanton} data-ort={element.ort} data-plz={element.plz} data-commune={element.commune}>
                                        {element.plz + " " + element.ort + (element.ort === element.commune ? '' : (" (" + element.commune + ")"))}
                                    </div>
                                )
                            })}
                        </div>
                    )}
        </Grid>
    )
}

export default PLZInput