import React from 'react'
import CustomContainer from '../Components/Container'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Input from '../Components/Input'
import { ReactComponent as Person } from '../assets/images/grayperson.svg'
import { ReactComponent as Email } from '../assets/images/graymail.svg'
import { ReactComponent as Subject } from '../assets/images/subject.svg'
import { ReactComponent as Message } from '../assets/images/message.svg'
import TextAreaInput from '../Components/TextAreaInput'

const Title = styled.span`
    font-size: 35px;
    font-weight:700;
    line-height:40px;
`

const BlueText = styled.span`
    color:#0073C9;
`
const Desc = styled.span`
    margin:auto;
    display:block;
    width: 100%;
    max-width:661px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
`

const Button = styled.button`
    padding: 25px 100px;
    background-color: #0073C9;
    color: #FFFFFF;
    border: none;
    border-radius: 60px;
`

const Text = styled.span`
    font-size:16px;
    font-weight:400;
    line-height:20px;
    color:#171717;
    opacity:0.4;
`

const Section4 = () => {
    return (
        <CustomContainer>
            <SectionBreaker>
                <Grid container  flexDirection={'column'} rowSpacing={5} justifyContent={'center'} textAlign={'center'} >
                    <Grid item xs={12}>
                        <Title><BlueText>Kontaktieren</BlueText> Sie uns</Title>
                    </Grid>
                    <Grid item>
                        <Desc>Lorem ipsum dolor sit amet consectetur.
                            Non enim molestie augue urna consectetur lacus nulla pulvinar. Morbi ut dolor lacus placerat pharetra.
                        </Desc>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent={"center"} alignItems={'center'} rowSpacing={3}>
                            <Grid item xs={12}>
                                <Input
                                    style={{ maxWidth: "599.39px", margin: 'auto' }}
                                    svg={<Person />}
                                    input={'Vorname und Nachname'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    style={{ maxWidth: "599.39px", margin: 'auto' }}
                                    svg={<Email style={{ opacity: '0.4' }} />}
                                    input={'Email'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    style={{ maxWidth: "599.39px", margin: 'auto' }}
                                    svg={<Subject  style={{ opacity: '0.5' }}/>}
                                    input={'Betreff'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextAreaInput
                                    style={{ maxWidth: "599.39px", margin: 'auto' }}
                                    svg={<Message  style={{ opacity: '0.5' }}/>}
                                    placeholder={'Beschreibung'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button>
                            Senden
                        </Button>
                    </Grid>
                    <Grid item>
                        <Text>Lorem ipsum dolor sit amet consectetur. Non enim molestie augue urna*</Text>
                    </Grid>
                </Grid>
            </SectionBreaker>
        </CustomContainer>
    )
}

export default Section4