import React from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const InputWrapper = styled.div`
    width:100%;
    height:55px;
    border-radius: 15px;
    text-align:left;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    border: ${({ error }) => (error ? '1px solid red' : '1px solid #EFF0F6')};
`

const InputItem = styled.span`
    font-size: 17px;
    font-weight: 400;
    line-height:20px;
    padding:20px 0px;
    padding-left: 15px;
    color:#171717;
    input {
        width: 100%;
    }
`

const Inputtext = styled.span`
    opacity:0.5;
    
`;

const ButtonWrapper = styled.div`
    border-left:1px solid #EFF0F6;
    justify-content:flex-end;
    padding:2px 10px;
`

const Button = styled.button`
    background-color:transparent;
    border:none;
    color:#0073C9;
    font-size:18px;
    font-weight:400;
    line-height:20px;
`
const Input2 = ({ svg, input, style, formData, handleChange, name, error }) => {
    return (
        <Grid container>
            <InputWrapper style={{ ...style }} error={error}>
                <Grid container pt={1.6} textAlign={'left'}>
                    {
                        svg && (
                            <Grid item xs={'auto'}>

                                <InputItem>
                                    {svg}
                                </InputItem>

                            </Grid>
                        )
                    }
                    <Grid item xs>
                        <InputItem>
                            <Inputtext>
                                <input style={{outline:"none", border:"none"}}   name={name} onChange={handleChange} type="text" placeholder={input} />
                            </Inputtext>
                        </InputItem>
                    </Grid>
                    <Grid item >
                        <ButtonWrapper>
                            {/* <Button>
                                Send
                            </Button> */}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </InputWrapper>
        </Grid>
    )
}

export default Input2