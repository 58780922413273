import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
    margin-top: 80px;
    
    @media (max-width: 1535.98px) { 
        margin-top: 80px;
    }


    @media (max-width: 1199.98px) { 
        margin-top: 60px;
    }

    @media (max-width: 899.98px) {
        margin-top: 50px;

    }

    @media (max-width: 599.98px) { 
        margin-top: 50px;
    
    }
`
function SectionBreaker({children}) {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default SectionBreaker