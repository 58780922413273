import React, { useState } from 'react';
import { motion } from "framer-motion";
import styled from 'styled-components';
import { ReactComponent as Logo } from '../assets/images/DLFINANCE-01.svg'
import axios from 'axios';

const SEGMENTS = [
    { color: '#4A90E2', label: '10 CHF' },
    { color: 'white', label: '20 CHF' },
    { color: '#72B6FF', label: '40 CHF' },
    { color: 'white', label: '100 CHF' },
    { color: '#4A90E2', label: '200 CHF' },
    { color: 'white', label: '10 CHF' },
    { color: '#72B6FF', label: '20 CHF' },
    { color: 'white', label: '40 CHF' },
    { color: '#4A90E2', label: '100 CHF' },
    { color: 'white', label: '200 CHF' },
];

const Button = styled.button`
    padding: 20px 90px;
    background-color: #0073C9;
    color: #FFFFFF;
    border: none;
    border-radius: 15px;
    &:disabled {
        opacity:0.5;
    }
`

const Wheel = ({ setStep, formData, setFormData }) => {
    const [rotation, setRotation] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const [ShowModal, setShowModal] = useState('')
    const [DidSpin, setDidSpin] = useState(false)
    const [Loading, setLoading] = useState(false)
    const spinWheel = async () => {
        if (isSpinning) return;

        var desiredLabel
        setDidSpin(true)
        setLoading(true)
        await axios.post('https://dlfinance.insulana.ch/get-random-item').then((response) => {

            desiredLabel = response.data.value + ' CHF'
            // console.log(formData);

            setIsSpinning(true);
            setLoading(false)
        })




        // Find the index of the item you want the wheel to land on, e.g., '100 CHF'

        const desiredIndex = SEGMENTS.findIndex(segment => segment.label === desiredLabel);

        // Each segment represents 360 / SEGMENTS.length degrees
        const segmentAngle = 360 / SEGMENTS.length;

        // Calculate the angle to land on the desired segment
        const targetRotation = segmentAngle * desiredIndex;

        // Spin for 4 full rotations plus the exact angle needed to land on the desired segment
        const newRotation = rotation + 1440 + targetRotation;

        setRotation(newRotation);

        // Set the spinning time shorter for faster spinning
        setTimeout(() => {
            setIsSpinning(false);
            setShowModal(SEGMENTS[desiredIndex].label);

            setFormData({ ...formData, item: SEGMENTS[desiredIndex].label });
        }, 4000); // Shorten the timeout to match the faster animation
    };

    React.useEffect(() => {
        if (ShowModal !== '') {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [ShowModal]);

    return (
        <div style={{ overflow: 'hidden' }}>
            {ShowModal !== '' && (
                <div className="congratModal">
                    <div className="congrat-modal-content">
                        <div className="confetti">You won {ShowModal}!</div>
                        <p> Our team will contact you as soon as possible. If you have any question fill the form below to contact us. Thank you for participating </p>
                        <Button
                            onClick={() => { setShowModal('') }}
                            style={{ padding: '12px', width: '100%', marginTop: 20 }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            )}
            <div style={{ position: 'relative', overflow: 'hidden', transform: 'rotate(-72deg)', maxWidth: 600, maxHeight: 600, boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px', borderRadius: '1100px' }}>
                <motion.svg
                    viewBox="-50 -50 100 100"
                    animate={{ rotate: rotation }}
                    transition={{ type: "spring", duration: 4, bounce: 0 }}
                    style={{ transform: 'rotate(25deg)' }}
                >


                    <circle r="50" fill="#fff" />

                    {SEGMENTS.map((segment, index) => {
                        const angle = ((index * 360) / SEGMENTS.length);
                        const nextAngle = ((index + 1) * 360) / SEGMENTS.length;
                        const midAngle = (angle + nextAngle) / 2;

                        return (
                            <g key={index}>
                                <path
                                    d={`M 0 0 L ${(49 * Math.cos(angle * Math.PI / 180))} ${(-49 * Math.sin(angle * Math.PI / 180))} A 49 49 0 0 0 ${49 * Math.cos(nextAngle * Math.PI / 180)} ${-49 * Math.sin(nextAngle * Math.PI / 180)} Z`}
                                    fill={segment.color}
                                />
                                <text
                                    x={45 * Math.cos(midAngle * Math.PI / 180)}
                                    y={-45 * Math.sin(midAngle * Math.PI / 180)}
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize="2.5"
                                    fill={segment.color === 'white' ? '#4A90E2' : 'white'}
                                    transform={`rotate(${90 - midAngle} ${45 * Math.cos(midAngle * Math.PI / 180)} ${-45 * Math.sin(midAngle * Math.PI / 180)})`}
                                >
                                    {segment.label}
                                </text>
                            </g>
                        );
                    })}
                </motion.svg>


                <div style={{
                    objectFit: 'cover',
                    display: 'grid',
                    placeItems: 'center',
                    width: '20%',
                    height: '20%',
                    position: 'absolute',
                    top: 'calc(50% - 10%)',
                    left: 'calc(50% - 10%)',
                    background: 'white',
                    padding: '15px',
                    borderRadius: '10000px',
                    transform: 'rotate(72deg)'
                }}>
                    <Logo />
                </div>
                <div style={{
                    position: 'absolute',
                    top: '36%',
                    right: 7,
                    width: '3rem',
                    height: '5px',
                    backgroundColor: '#F56565',
                    transform: 'rotate(-17deg)',
                    borderRadius: '9999px',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
                }} />

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>

                <Button
                    onClick={!DidSpin ? spinWheel : () => { }}
                    disabled={DidSpin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                    {!Loading ? (
                        'Spin'
                    ) : (
                        <><span className='loader'></span> Loading...</>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default Wheel;