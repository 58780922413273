import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Person } from '../assets/images/menuperson.svg';
import { ReactComponent as Gift } from '../assets/images/giftmenu.svg';
import { ReactComponent as Location } from '../assets/images/locationmenu.svg';
import { ReactComponent as Mail } from '../assets/images/emailmenu.svg';

const BoxWrapper = styled.div`
    width:65px;
    height:65px;
    display:flex;
    border-radius: 15px;
    border: 1px solid #EFF0F6;
    box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
    justify-content:center;
    align-items:center;
`
const Wrapper = styled.div`
    position:fixed;
    right:5%;
    top:0px;
    height: 100vh;
    display: flex;
    align-items:center;

    @media (max-width: 1250.98px) { 
       display:none;
    }
      
    @media (max-width: 899.98px) {
        display:none;
    }

    @media (max-width: 599.98px) { 
        display:none;
    }
`

const Menu = () => {
    return (
        <Wrapper>
            <Grid rowSpacing={2} container flexDirection={'column'} >
                <Grid item>
                    <a href='#home'>
                        <BoxWrapper>
                            <Person/>
                        </BoxWrapper>
                    </a>
                </Grid>
                <Grid item>
                    <a href='#aboutus'>
                        <BoxWrapper>
                            <Gift />
                        </BoxWrapper>
                    </a>
                </Grid>
                {/* <Grid item>
                    <a href='#findus'>
                        <BoxWrapper>
                            <Location />
                        </BoxWrapper>
                    </a>
                </Grid> */}
                <Grid item>
                    <a href='#getintouch'>
                        <BoxWrapper>
                            <Mail />
                        </BoxWrapper>
                    </a>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default Menu