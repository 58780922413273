import React from 'react'
import { Grid } from '@mui/material'
import { ReactComponent as DLFinance } from '../assets/images/dlfinance.svg'
import styled from 'styled-components'
import SectionBreaker from './SectionBreaker';

const Text = styled.p`
    display:block;
    color:#171717;
    opacity:0.4;
    font-size:18px;
    text-align:center;
`;

const FooterWrapper = styled.div`
    padding:30px 150px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-top:1px solid #EFF0F6;

    @media (max-width: 899.98px) {
        flex-direction:column;
        justify-content:center;
        padding:30px 90px;
    }

    @media (max-width: 599.98px) { 
        flex-direction:column;
        justify-content:center;
        padding:30px 90px;
    }

`
const Footer = () => {
    return (
        <SectionBreaker>
            <FooterWrapper>
                <Grid item >
                    <DLFinance />
                </Grid>
                <Grid item>
                    <Text>Copyright © 2024 DL Finance | All Rights Reserved </Text>
                </Grid>
            </FooterWrapper>
        </SectionBreaker>
    )
}

export default Footer